<template>
  <div class="login publicVCenter">
    <img src="@/assets/image/phone/img4.png" class="bg" alt="嗨喵悦动,嗨喵互动,嗨喵手机端"/>
    <div class="advatage publicVCenter">
      <div class="item">互动收益80%，邀请收益12%</div>
      <div class="item">微信零钱自动到账</div>
    </div>
    <div class="loginForm publicVCenter">
      <div class="phoneInput">
        <el-input placeholder="手机号" v-model="phone" maxlength="11">
          <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
        </el-input>
      </div>
      <div class="passwordInput">
        <el-input
          placeholder="密码"
          v-model="password"
          show-password
          maxlength="20"
        >
          <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
        </el-input>
      </div>
      <div class="loginBtn publicHvCenter" @click="login">登录</div>
      <div class="registerEntry" @click="toRegister">去注册</div>
    </div>
  </div>
</template>

<script>
import { inputLogin } from '@/api/index';

let btnLock = false;

export default {
  name: 'phoneLogin',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  data() {
    return {
      phone: '',
      password: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    login() {
      if (!this.phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!this.password) {
        this.$message.error('请输入密码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      const params = {
        phone: this.phone,
        password: this.password,
      };
      if (this.$store.state.bindId) {
        params.bindId = this.$store.state.bindId;
      }
      if (this.$store.state.h5_openid) {
        params.h5_openid = this.$store.state.h5_openid;
      }
      inputLogin(params)
        .then((res) => {
          console.log(res);
          if (res.token) {
            this.$router.push({
              path: '/phoneMain',
            });
            this.$store.commit('updateUserInfo', {
              userName: res.emcee_name,
              headImg: res.avator,
              phone: res.phonenumber,
              token: res.token,
            });
          } else if (res.code === '500') {
            this.$message.error(res.errMsg);
          }
          btnLock = false;
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    toRegister() {
      this.$router.push({
        path: '/phoneRegister',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 90vw;
  padding-bottom: 12vw;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .advatage {
    flex-direction: column;
    position: relative;
    .item {
      font-size: 4.2vw;
      font-weight: 400;
      color: #333;
      margin: 3vw 0;
    }
  }
  .loginForm {
    width: 80vw;
    height: 100%;
    flex-direction: column;
    .phoneInput {
      margin-top: 6vw;
      width: 100%;
    }
    .passwordInput {
      margin-top: 3vw;
      width: 100%;
    }
    .loginBtn {
      width: 78vw;
      height: 12vw;
      background: #2572e6;
      border-radius: 6.267vw;
      color: #fefdfb;
      font-weight: 500;
      font-size: 4.8vw;
      margin-top: 8vw;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .registerEntry {
      color: #2572e6;
      font-size: 3.8vw;
      margin-top: 6vw;
    }
    .el-input::v-deep {
      font-size: 4vw;
      .el-input__inner {
        padding-left: 6vw;
        height: 9vw;
      }
      .el-input__icon {
        width: auto;
      }
      .el-input__clear {
        font-size: 4vw;
        margin-right: 2vw;
      }
    }
  }
}
</style>
